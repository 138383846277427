import React, { useEffect, useState } from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import { Box, Paper, Typography } from '@mui/material';
import { postData, request } from "../utils/api";
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded';
// import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
// import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined';

ChartJS.register(ArcElement, Tooltip);

export default function ChartComponent() {
  const [myPoint, setMyPoint] = useState(0)
  const [stakingPoint, setStakingPoint] = useState(0);

  useEffect(() => {
    async function fetchData() {
      const login = await window.klaytn.enable();
      const requestData = { walletId: login[0] }

      const data = await postData('/users/myitems', requestData);
      setMyPoint(data.myPoint);

      const stakingData = await request('/info/stakingCount', {});
      setStakingPoint(stakingData.point);
    }

    fetchData();
  }, [])

  let data = {
    labels: ["total point", "my point"],
    datasets: [
      {
        // data: [stakingPoint, myPoint],
        data: [(stakingPoint - myPoint), myPoint],
        backgroundColor: [
          'RGBA(167, 202, 237, 0.1)', // un
          '#1976d2', // staking , #90caf9
        ],
        borderColor: [
          'rgb(167, 202, 237)'
        ],
        borderWidth: 1,
      },
    ],
  };

  let options = {
    plugins: {
      tooltip: {
        titleFont: {
          size: 100
        },
        bodyFont: {
          size: 20
        },
        footerFont: {
          size: 20 // there is no footer by default
        },
        callbacks: {
          label: function (context) {
            var data = context.dataset.data,
              label = context.label,
              currentValue = context.raw,
              total = 0;

            for (var i = 0; i < data.length; i++) {
              total += (data[i] * 1);
            }
            var percentage = parseFloat((currentValue / total * 100).toFixed(1));

            if (label == 'total point') {
              return label + ": " + total;
            } else {
              return label + ": " + currentValue + ' (' + percentage + '%)';
            }

          },
        }
      }
    }
  }

  return (
    <>
    { (stakingPoint > 0) && <Box sx={{ width: 250, height: 200, mt: 6, display: 'flex', flexDirection: 'column', justifyContent: 'space-around', alignItems: 'center' }}>
      <Pie data={data} options={options} /> 
      </Box>
    }
    {
      (stakingPoint <= 0) && 
      <Box sx={{ width: 300, height: 200, mt: 6, display: 'flex', flexDirection: 'column', justifyContent: 'space-around', alignItems: 'center' }}>
        <Paper elevation={0} sx={{width:'100%', height: '100%', display: 'flex', flexDirection: 'column', justifyContent:'center', alignItems: 'center'}}>
          <ErrorOutlineRoundedIcon sx={{ color : 'red', fontSize:50 }} /> 
            <Typography variant="h6" component="div" sx={{ textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 1, fontFamily: 'SB_L' }}>
              새로운 라운드가 시작되는 당일에는 누적된 포인트가 없습니다.
            </Typography>
        </Paper>
      </Box>
    }
    {/* <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', mt: 2 }}>
      <Button variant="contained" startIcon={<LockOutlinedIcon />} sx={{ mr: 1, pl: 4, pr: 4 }}>
        TotalPoint
      </Button>
      <Button variant="outlined" startIcon={<LockOpenOutlinedIcon />} sx={{ pl: 4, pr: 4 }}>
        MyPoint
      </Button> 
    </Box> */}
    </>
  )
}