import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
// import { Provider } from "react-redux";
// import promiseMiddleware from 'redux-promise';
// import { applyMiddleware, legacy_createStore as createStore} from 'redux';
// import ReduxThunk from 'redux-thunk';
// import Reducer from './_reducers';

const root = ReactDOM.createRoot(document.getElementById('root'));

// const createStoreWithMiddleware = applyMiddleware(promiseMiddleware, ReduxThunk)(createStore)
root.render(
  // <Provider store={createStoreWithMiddleware(Reducer, window.__redux_devtools_extension__ && window.__redux_devtools_extension__())}>
    <App />
  // </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
