export const storage = localStorage;

export const getItem = (key, defaultValue) => {
  try {
    const value = storage.getItem(key)
    // key 에 해당하는 값이 있다면 parsing 하고 없으면 default value 리턴
    return value ? JSON.parse(value) : defaultValue
  } catch {
    // parsing 하다가 에러가 생기면 default value 리턴
    return defaultValue
  }
}

export const setItem = (key, value) => {
  try {
    storage.setItem(key, JSON.stringify(value))
  } catch {
    // ignore
  }
}

export const removeItem = (key) => {
  try {
    storage.localStorage(key)
  } catch {
    //ignore
  }
}