import React from 'react'
import { Modal, Box, Button } from '@mui/material';

function NoticeModal({open, handleClose}) {
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 1000,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} color={"primary"}>
          MTDZ 스테이킹 <br />
          - 보유한 MTDZ를 스테이킹 하여 월간 분배수익을 배정 받을 수 있습니다.<br /><br />
          스테이킹 인정 기준<br />
          - 스테이킹을 실행하면 매일 00시를 기준으로 1MTDZ 당 1Point를 받습니다.<br />
          - 스테이킹을 해제한 경우 누적된 포인트는 초기화 됩니다.<br />
          - 매일 00시 00분 00초를 기준으로 스테이킹된 MTDZ에 대하여 소유 확인을 실시합니다.<br />
          - 소유권 변동이 발생한 MTDZ는 스테이킹 효력을 상실하며 수익을 배정 받지 못합니다.<br />
          - 소유 변경된 MTDZ에 대하여 새로이 스테이킹 실행하는 경우 기존의 스테이킹 이력은 무효화 됩니다.<br /><br />
          스테이킹 보상의 확정<br />
          - 스테이킹 보상은 매월 말 23시 50분 00초에 확정됩니다.<br /><br />
          스테이킹 라운드<br />
          - 스테이킹 포인트는 매월 1일에 갱신됩니다.<br />
          - 매월 1일 00시 00분 00초를 기준으로 스테이킹 라운드가 시작되며, 새롭게 포인트가 누적됩니다.<br />
          - 기존에 스테이킹을 유지하고 있는 MTDZ의 경우 자동으로 갱신됩니다.<br /><br />
          # 나의 월간 배분 수익 = [월간 총 배분수익] x [나의 총 포인트합] / [전체 홀더의 스테이킹 포인트합]<br /><br />
          
          수수료<br />
          - Meta Toy DragonZ: 2.5%<br />
          - Meta Toy Squad: 1.5%<br />
          - Meta Toy Bricks: 1.5%<br />
          - Meta Toy Gamers: 1.5%<br />
          - P2E Game: 추후 예정 <br />

          <div style={{ display: 'flex', justifyContent: 'end' }}>
            <Button variant="contained" sx={{ mr: 1, pl: 4, pr: 4 }} onClick={handleClose}>
              닫기
            </Button>
          </div>
        </Box>
      </Modal></>


  )
}

export default NoticeModal