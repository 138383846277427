import React from 'react';

// import ABI from "../static/ABI";
// import Caver from 'caver-js';

import mainContentImg from "../static/images/main-content.png";

import Box from '@mui/material/Box';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import IconButton from '@mui/material/IconButton';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';

function HeaderContainer({ onClickDarkmode, darkmode, check }) {
  const klaytn = window.klaytn;

  const open = async () => {
    // 서버에서 서명 request string 
    await klaytn.enable();
    // const login = 
    // if (login) {
      // const cav = new Caver(klaytn);
      // const agContract = new cav.klay.Contract(ABI, '0x2d5ee6a91f486278a7078eab13383f5da0ef9dc1');

      // var msg;
      // var salt = 'xwczxfzqOZ';
      // await fetch()
      // console.log('지갑주소', login[0]);

      // await cav.klay.sign(salt, login[0]).then(function (signedmsg) {
      //   msg = signedmsg;
      //   console.log('test', msg);
      //   // 지갑주소 + 서명된 전체 스트링
      //   var addr = cav.klay.accounts.recover(salt, msg);
      //   console.log("addr: " + addr);
      // });

      // agContract.methods.ownerOf(1).call().then(data => console.log('1', data));
      // agContract.methods.ownerOf(2).call().then(data => console.log('2', data));
      // agContract.methods.ownerOf(3).call().then(data => console.log('3', data));
    // }
  }

  return (
    <header className="App-header">
      <Box sx={{ display: "flex", width: "100%", justifyContent: 'space-between', padding: '10px' }}>
        <img src={mainContentImg} className="App-logo" alt="logo" />
        <Box>
          <IconButton aria-label="login" color="inherit" onClick={onClickDarkmode}>
            {darkmode === 'dark' ? <Brightness7Icon sx={{ fontSize: 34 }} /> : <Brightness4Icon sx={{ fontSize: 34 }} />}
          </IconButton>

          {!check &&
            <IconButton aria-label="login" color="inherit" onClick={open} >
              <AccountBalanceWalletOutlinedIcon sx={{ fontSize: 34 }} />
            </IconButton>}
        </Box>
      </Box>
    </header >
  )
}

export default HeaderContainer