import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box';
import Appbar from "../components/Appbar";
import { postData, request } from "../utils/api";
import Caver from 'caver-js';

function MainAppbarContainer({ check, syncStakingCount }) {
  const [login, setLogin] = useState({});
  const [info, setInfo] = useState({});
  const [myItemsList, setMyItemsList] = useState([]);
  const [sign, setSign] = useState('');
  const [stakingPoint, setStakingPoint] = useState(0);

  const klaytn = window.klaytn;

  // 서명 체크
  const setList = (data) => {
    setInfo(data);
    let strArr = [];

    for (let objKey in data.myItemsList) {
      if (data.myItemsList.hasOwnProperty(objKey)) {
        strArr.push(data.myItemsList[objKey]);
      }
    }

    setMyItemsList(strArr.map(item => {
      return {
        ...item,
        checked: false
      }
    }));

  }

  useEffect(() => {
    async function fetchData() {
      if(!check) {
        return;
      }

      const login = await klaytn.enable();
      setLogin(login);

      const requestData = { walletId: login[0] }
      const data = await postData('/users/myitems', requestData);
      setList(data);

      const stakingData = await request('/info/stakingCount', {});
      setStakingPoint(stakingData.point);

      let strArr = [];

      for (let objKey in data.myItemsList) {
        if(data.myItemsList.hasOwnProperty(objKey)) {
          strArr.push(data.myItemsList[objKey]);
        }
      }

      setMyItemsList(strArr.map(item => {
        return {
          ...item,
          checked: false
        }
      }));
    }
    
    setTimeout(() => {
      fetchData();
    }, [100])

  }, [check])

  const signCheck = async () => {
    const login = await klaytn.enable();
    setLogin(login);
    if (login) {
      const cav = new Caver(klaytn);
      const requestData = { addr: login[0] };

      const data = await postData('/signRq', requestData)
      const salt = data.salt;

      await cav.klay.sign(salt, login[0]).then(function (signedmsg) {
        setSign(signedmsg);
      });
    }
  }

  const onChangeCardColor = (idx) => {
    const newItems = myItemsList.map((item, index) => {
      if(index === idx*1) {
        const checked = item.checked
        const newItem = {
          ...item,
          checked: !checked,
        }
  
        return newItem;
      }else{
        return item;
      }
    })

    setMyItemsList(newItems);
  }

  return (
    <Box sx={{ mt:2 }}>
      <Appbar 
        info={info} 
        myItemsList={myItemsList}
        check={check}
        signCheck={signCheck}
        onChangeCardColor={onChangeCardColor}
        addr={login[0]}
        sign={sign}
        setList={setList}
        syncStakingCount={syncStakingCount}
        stakingPoint={stakingPoint}
      />
    </Box>
  )
}

export default MainAppbarContainer;