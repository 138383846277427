import React from 'react'
import Box from '@mui/material/Box';
// import mainContentImg from "../static/images/main-content.png";
import Chart from "./ChartComponent";

function MainContentLeftArea({ stakingPoint, myPoint }) {

  return (
    <div className='mainContent_area'>
      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Chart stakingPoint={stakingPoint} myPoint={myPoint} />
        {/* <img src={mainContentImg} style={{width: '520px', marginTop: '20px', marginLeft: '20px'}} /> */}
      </Box>
    </div>
  )
}

export default MainContentLeftArea