import React, { useEffect, useState } from 'react';

import "./static/fonts/font.css";
import './App.css';
import { BrowserView, MobileView, browserName } from 'react-device-detect'
import { getItem, setItem } from "./utils/localStorage.js";

import HeaderContainer from "./container/HeaderContainer";
import MainContentContainer from "./container/MainContentContainer";
import MainAppbarContainer from "./container/MainAppbarContainer";
import FooterContainer from "./container/FooterContainer";

import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';

import { ThemeProvider, createTheme } from '@mui/material/styles';
import { request } from "./utils/api";

function App() {
  if(browserName == 'Edge'){
    alert('Edge 브라우저에서는 이용이 불가능합니다. \ngoogle Chrome을 이용해주세요.');
  }

  const klaytn = window.klaytn;
  const [mode, setMode] = React.useState("dark");
  const [check, setCheck] = React.useState(false);
  const [stakingCount, setStakingCount] = useState(0);
  const [timeData, setTimeData] = useState(null);
  const [addr, setAddr] = useState('');

  const darkTheme = createTheme({
    palette: {
      mode
    },
  });

  const syncStakingCount = async () => {
    const stakingCount = await request('/info/stakingCount');
    setStakingCount(stakingCount.count);
    setTimeData(stakingCount.nextround);
  }

  useEffect(() => {
    async function stakingCountFunction () {
      await syncStakingCount();
    }

    stakingCountFunction();
  }, [])
  

  useEffect(() => {
    const darkmode = getItem('darkmode') ? getItem('darkmode') : 'dark';
    setMode(darkmode);

  }, [])

  useEffect(() => {
    async function checkLogin () {
      // if( === ) 
      const state = await window.klaytn.publicConfigStore.getState(); // 로그인 실시간 체크

      if(state.isUnlocked) {
        setCheck(true);
      }
      
      if(state.isUnlocked === undefined) {
        // checkLogin();
        // console.log(await klaytn.publicConfigStore.getState())
      }
    } 

    setTimeout(() => {
      checkLogin();    
    }, 1)

  }, [])

  // const [check, setCheck] = useState(false);
  useEffect(() => {
    klaytn.publicConfigStore.subscribe(async () => {

      if (!klaytn) {
        return;
      }

      const state = await klaytn.publicConfigStore.getState(); // 로그인 실시간 체크

      if(state.isUnlocked) {
        setCheck(true);
      }
      
      // else{
      //   location.reload();
      // }
      // console.log(state, check);
    });
  }, [])


  const onClickDarkmode = () => {
    let darkmode = mode === 'light' ? 'dark' : 'light';

    setMode(darkmode);
    setItem('darkmode', darkmode);
  }

  return (
    <ThemeProvider theme={darkTheme}>
      <div className="App">
      <BrowserView>
        <CssBaseline />
        <Container fixed sx={{ maxWidth: 1200, paddingLeft: 1, height: '100vh' }}>
          <Box>
            <HeaderContainer onClickDarkmode={onClickDarkmode} darkmode={mode} check={check} />
            <MainContentContainer check={check} stakingCountNumber={stakingCount} timeData={timeData} addr={addr} />
            <MainAppbarContainer check={check} syncStakingCount={syncStakingCount}/>
          </Box>
        </Container>

        <FooterContainer />
      </BrowserView>
      <MobileView>
        <div style={{display: 'flex', justifyContent:'center', alignItems:'center', height: '100vh'}}>
          MTDZ Staking은 PC크롬에서만 이용 가능하오니 참고 바랍니다.
        </div>
      </MobileView>
      </div >
    </ThemeProvider>
  );
}

export default App;