import React, { useEffect, useState } from 'react'
import Typography from '@mui/material/Typography';
// import Modal from '@mui/material/Modal';
import NoticeModal from "./NoticeModal";
import LinearProgress from '@mui/material/LinearProgress';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';

// import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
// import SavingsOutlinedIcon from '@mui/icons-material/SavingsOutlined';

// import { useInterval } from 'react-use';
// import Moment from 'react-moment';
import moment from 'moment';
import 'moment/locale/ko';

function MainContentLeftArea({ stakingCount = 0, depositMoney = 100000000, timeData }) {
  const [percent, setPersent] = useState(0);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // const targetTime = moment().endOf('month').format('YYYY-MM-DD').valueOf();
  // const targetTime = new Date("2022-07-01").getTime();

  const targetTime = moment(timeData, "YYYY-MM-DD hh:mm:ss").valueOf();
  const [currentTime, setCurrentTime] = useState(Date.now());

  const timeBetween = targetTime - currentTime;
  const seconds = Math.floor((timeBetween / 1000) % 60);
  const minutes = Math.floor((timeBetween / 1000 / 60) % 60);
  const hours = Math.abs(Math.floor((timeBetween / (1000 * 60 * 60)) % 24));
  const days = Math.abs(Math.floor(timeBetween / (1000 * 60 * 60 * 24)));

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(Date.now());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const data = ((stakingCount/9999)*100).toFixed(2)*1;
    setPersent(data);
  }, [stakingCount])


  const nothingFunc = () => {
    console.log("nothingFunc");
  }

  return (
    <div className='mainContent_area'>
      {/* <Box sx={{ width: '100%', display: 'flex', marginLeft: 3 }}>
        <SavingsOutlinedIcon sx={{ fontSize: 46, marginRight: 1 }} onClick={nothingFunc} />
        <Typography variant="h3" component="div" style={{ fontFamily: 'SB_B' }}>
          {depositMoney.toLocaleString(undefined, { maximumFractionDigits: 0 })}KLAY
        </Typography>
      </Box> */}

      <Box sx={{ width: '100%', ml: 0, display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
        <Typography variant="h4" component="div" sx={{ fontFamily: 'SB_M', marginLeft: 4, fontSize: 24 }}>
        스테이킹 참여율 {stakingCount}/9999 MTDZ
        </Typography>
      </Box>

      <Box sx={{ width: '100%', ml: 1, marginTop: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <LinearProgress variant="determinate" value={percent} style={{ width: '84%' }} />
        <Typography variant="subtitle2" component="div" sx={{ fontFamily: 'SB_B', marginLeft: 2 }}>
          {percent}%
        </Typography>
      </Box>

      <Box sx={{ width: '100%', ml: 2.5, mt: 1, display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
        <Button variant="contained"  sx={{ mr: 1, pl: 4, pr: 4, pt:1, fontFamily: 'SB_L' }} onClick={handleOpen}>
          스테이킹 가이드
        </Button>
      </Box>

      <NoticeModal open={open} handleClose={handleClose} />
      {/* endIcon={<QuestionMarkIcon />} */}

      <Box sx={{ width: '100%', marginLeft: 1, mt: 16, display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
        <Typography variant="h4" component="div" sx={{ fontFamily: 'SB_M', marginLeft: 2, fontSize: 24 }}>
          스테이킹 보상 확정까지
        </Typography>
      </Box>
      <Box sx={{ width: '100%', height: '36px', marginLeft: 3, marginTop: 0, display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
        <Paper elevation={3} sx={{ width: 30, height: 30, display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: 'primary' }}>{days}</Paper>
        <Typography variant="h4" component="div" sx={{ fontFamily: 'SB_M', fontSize: 20, mt: 0.5, ml: 0.3, mr: 0.5, }}>
          일
        </Typography>
        <Paper elevation={3} sx={{ width: 30, height: 30, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>{hours}</Paper>
        <Typography variant="h4" component="div" sx={{ fontFamily: 'SB_M', m: 1, fontSize: 20, mt: 1.5 }}>
          시
        </Typography>
        <Paper elevation={3} sx={{ width: 30, height: 30, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>{minutes}</Paper>
        <Typography variant="h4" component="div" sx={{ fontFamily: 'SB_M', m: 1, fontSize: 20, mt: 1.5 }}>
          분
        </Typography>
        <Paper elevation={3} sx={{ width: 30, height: 30, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>{seconds}</Paper>
        <Typography variant="h4" component="div" sx={{ fontFamily: 'SB_M', m: 1, fontSize: 20, mt: 1.5 }}>
          초 남았습니다.
        </Typography>
      </Box>

      {/* <Typography id="modal-modal-description" sx={{ mt: 2 }}>
        Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
      </Typography> */}
      {/* <Chip label="07" onClick={nothingFunc} />일
        <Chip label="18" />시
        <Chip label="36" />분
        <Chip label="59" />초 */}
    </div>
  )
}

export default MainContentLeftArea