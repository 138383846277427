import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import SavingsOutlinedIcon from '@mui/icons-material/SavingsOutlined';
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined';
import RemoveOutlinedIcon from '@mui/icons-material/RemoveOutlined';

import { Button, CardActionArea, CardActions } from '@mui/material';

export default function ProfileCard({ item, index, onChange }) {
  return (
    <Card sx={{ width: 215, height: 330, mt: 1, mb: 1, ml: 1, background: item.checked? '#1976d2': '' }} onClick={onChange}>
      <CardActionArea className='actionArea'>
        <CardMedia
          component="img"
          height="215"
          image={item.img}
          alt={item.name}
          data-index={index}
        />
        <CardContent sx={{ p: 0 }}>
          <Typography variant="h6" component="div" data-index={index} sx={{ p: 1, fontSize: '1rem', textAlign: 'center',  color: item.checked? '#fff': '' }}>
            {item.name}
          </Typography>
          <Typography variant="body2" component="div" data-index={index} sx={{ p: 0, fontSize: '1rem', display: 'flex', justifyContent: 'center', alignItems: 'center',  color: item.checked? (item.day > 10 ? 'red': '#fff'): '' }}>
            {item.staking ? <><SavingsOutlinedIcon sx={{ mr : 1}} data-index={index} /><Typography sx={{ fontSize: 16 }} data-index={index}>{item.day}일</Typography></> : <RemoveOutlinedIcon data-index={index} />}
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions className="cardActions" data-index={index} sx={{ display: 'flex', justifyContent: 'center', alignContent: 'center' }}>
        <Button size="small" color="primary" className='stakingArea' data-index={index}>
        {item.staking ? <LockOutlinedIcon sx={{ color: item.checked? '#fff': ''}} /> : <LockOpenOutlinedIcon   /> }
        </Button>
      </CardActions>
    </Card>
  );
}

