import React from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Link from '@mui/material/Link';
import sns_icon01 from "../static/images/sns_icon01.png";
import sns_icon02 from "../static/images/sns_icon02.png";
import sns_icon03 from "../static/images/sns_icon03.png";
import sns_icon04 from "../static/images/sns_icon04.png";
// import { grey } from '@mui/material/colors';

// const BootstrapButton = styled(Button)({
//   boxShadow: 'none',
//   textTransform: 'none',
//   fontSize: 16,
//   padding: '6px 12px',
//   border: '1px solid',
//   borderRadius: 40,
//   lineHeight: 1.5,
//   backgroundColor: '#000',
//   borderColor: '#0063cc',
//   boxShadow: '0px 5px 9.7px 0.3px rgb(0 0 0 / 70%)',
//   fontFamily: [
//     '-apple-system',
//     'BlinkMacSystemFont',
//     '"Segoe UI"',
//     'Roboto',
//     '"Helvetica Neue"',
//     'Arial',
//     'sans-serif',
//     '"Apple Color Emoji"',
//     '"Segoe UI Emoji"',
//     '"Segoe UI Symbol"',
//   ].join(','),
//   '&:hover': {
//     backgroundColor: '#000',
//   },
//   '&:active': {
//     backgroundColor: '#0062cc',
//   },
//   '&:focus': {
//     boxShadow: '0px 5px 9.7px 0.3px rgb(0 0 0 / 70%)',
//   },
// });

const ColorButton = styled(Button)(({ theme }) => ({
  border: 'none',
  width: '153px',
  height: '60px',
  borderRadius: '30px',
  fontSize: '11px',
  fontFamily: 'SB_M',
  color: 'rgba(255, 255, 255, 0.8)',
  fontWeight: '500',
  boxShadow: '0px 5px 9.7px 0.3px rgb(0 0 0 / 70%)',
  textDecoration: 'none',
  // color: theme.palette.getContrastText(grey[500]),
  backgroundColor: '#1b1b1b',
  '&:hover': {
    backgroundColor: "#189cf4",
    textDecoration: 'none'
  },
}));


function ButtonArea() {
  return (
    <Stack spacing={2} direction="row" sx={{width: '484px', ml: '64px'}}>
      <Link href="https://data.metatoydragonz.io/MTDZ_Terms_of_Use_EN.pdf" target={"_blank"} sx={{
         textDecoration: 'none'
      }}>
        <ColorButton variant="contained" sx={{width: '153px'}}>Terms of Use</ColorButton>
      </Link>
      <Link href="https://opensea.io/collection/mtdz-1" target={"_blank"} sx={{
         textDecoration: 'none'
      }}>
        <ColorButton variant="contained" sx={{width: '60px'}}>
          <img src={sns_icon01} />
        </ColorButton>
      </Link>

      
      <Link href="https://discord.com/invite/JRe5962rTa" target={"_blank"} sx={{
         textDecoration: 'none'
      }}>
      <ColorButton variant="contained" sx={{width: '60px'}}><img src={sns_icon03} /></ColorButton>
      </Link>
      
      <Link href="https://twitter.com/MetaToyDragonZ" target={"_blank"} sx={{
         textDecoration: 'none'
      }}>
      <ColorButton variant="contained" sx={{width: '60px'}}><img src={sns_icon02} /></ColorButton>
      </Link>

      <Link href="https://www.instagram.com/meta_toy_dragonz/" target={"_blank"} sx={{
         textDecoration: 'none'
      }}>
      <ColorButton variant="contained" sx={{width: '60px'}}><img src={sns_icon04} /></ColorButton>
      </Link>
    </Stack>
  )
}

export default ButtonArea