import React, { useState } from "react";
import {
  Box,
  Typography,
  Toolbar,
  AppBar,
  ButtonGroup,
  Button,
  Slide,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Card,
  Skeleton
} from "@mui/material";


import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import ProfileCard from "./ProfileCard";

import { postData } from "../utils/api";
import Caver from 'caver-js';

const theme = createTheme({
  palette: {
    primary: {
      main: '#ffffff',
    },
    secondary: {
      main: '#f44336',
    },
  },
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function Appbar({ info, myItemsList, check, signCheck, onChangeCardColor, addr, sign, setList, syncStakingCount, stakingPoint }) {
  const [choiceItems, setChoiceItems] = useState('');
  const [choiceIds, setChoiceIds] = useState('');
  const cav = new Caver(window.klaytn);

  const pointPercent = ((info.myPoint / stakingPoint)*100).toFixed(1);

  let stakingNumber = 0;

  if (myItemsList) {
    stakingNumber = myItemsList.filter(item => item.staking).length;
  }

  const [dialogOpen, setDialogOpen] = useState({
    allStaking: false,
    staking: false,
    unStaking: false,
  });

  const handleDialogOpen = e => {
    if (!check) return;

    if (myItemsList.length > 0) {
      setChoiceItems(myItemsList.filter(item => item.checked).map(item => item.name).join(',\n'));
      setChoiceIds(myItemsList.filter(item => item.checked).map(item => item.id));
    }

    const { name } = e.target;

    if (name !== 'allStaking') {

      if (myItemsList.filter(item => item.checked).length <= 0) {
        alert('선택된 상품이 없습니다.');
        return;
      };
    }

    setDialogOpen({
      ...dialogOpen,
      [name]: true,
    })
  }

  const handleDialogClose = () => {
    setDialogOpen({
      allStaking: false,
      staking: false,
      unStaking: false,
    })
  }

  const allStakingTransfer = async () => {
    const login = await window.klaytn.enable();
    if (login) {
      const requestData = { addr: login[0] };
      const data = await postData('/signRq', requestData)
      const salt = data.salt;
      const sign = await cav.klay.sign(salt, login[0]);

      const stakingData = {
        stakingReq: {
          addr: login[0],
          sign,
          stakingList: myItemsList.map(item => item.id),
        }
      }

      if (sign === '') return;
      const stakingRes = await postData('/staking/add', stakingData);
      if (stakingRes.rs === 0) {
        const requestData = { walletId: login[0] }
        // const data = await postData('/users/myitems', requestData);

        alert('정상 처리 되었습니다.')
        const myInfo = await postData('/users/myitems', requestData);
        setList(myInfo);
      } else {
        alert(`error : ${stakingRes.msg}`);
      }

      syncStakingCount();
      handleDialogClose();
    }
  }

  const stakingTransfer = async () => {
    const login = await window.klaytn.enable();
    if (login) {
      const requestData = { addr: login[0] };
      const data = await postData('/signRq', requestData)
      const salt = data.salt;
      const sign = await cav.klay.sign(salt, login[0]);
      const requestData2 = {
        stakingReq: {
          addr: login[0],
          sign,
          stakingList: choiceIds,
        }
      }

      if (sign === '') return;
      const data2 = await postData('/staking/add', requestData2);
      if (data2.rs === 0) {
        const requestData = { walletId: login[0] }
        alert('정상 처리 되었습니다.');
        const data = await postData('/users/myitems', requestData);
        setList(data);

      } else {
        alert(`error : ${data2.msg}`);
      }
      
      syncStakingCount();
      handleDialogClose();
    }

  }

  const unStakingTransfer = async () => {
    const login = await window.klaytn.enable();

    if (login) {
      const requestData = { addr: login[0] };

      const data = await postData('/signRq', requestData)
      const salt = data.salt;
      const sign = await cav.klay.sign(salt, login[0]);

      const requestData2 = {
        stakingReq: {
          addr: login[0],
          sign,
          stakingList: choiceIds,
        }
      }

      if (sign === '') return;
      const data2 = await postData('/staking/remove', requestData2);
      if (data2.rs === 0) {
        const requestData = { walletId: login[0] }

        alert('정상 처리 되었습니다.')
        const data = await postData('/users/myitems', requestData);
        setList(data);

      } else {
        alert(`error : ${data2.msg}`);
      }
      
      syncStakingCount();
      handleDialogClose();
    }
  }


  const onChange = e => {
    onChangeCardColor(e.target.dataset.index);
  }

  return (
    <Box sx={{ width: '1152px', height: '500px', '& .MuiButton-outlined, .MuiTypography-h6': { fontFamily: "SB_L" }, }}>
      <AppBar position={'relative'} color={'primary'} >
        <Toolbar sx={{ display: 'flex' }}>
          <Box sx={{ width: '40%', display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
            {check &&
              <Typography variant="h6" component="div">
                {`Total ${info.myItemsCount} MTDZ(s) (${stakingNumber ? stakingNumber : 0} Staked, ${info.myItemsCount - (stakingNumber ? stakingNumber : 0)} Unstaked)`}
              </Typography>
            }
            {!check && <Typography variant="h6" component="div" sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', mt: 1 }}>
              <AccountBalanceWalletOutlinedIcon sx={{ mr: 1, mt: -0.5 }} />로그인 후 이용해주세요</Typography>}
          </Box>
          <Box sx={{ flex: 1, display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
            {check &&
              <Typography variant="h6" component="div" sx={{ color: 'red', mr: 2 }}>
                나의 예상 수익 {info.myPoint} point ({ info.myPoint == 0? 0 : pointPercent }%)
                {/* 나의 예상 수익 {info.myPoint} point (0%) */}
              </Typography>
            }
            <ThemeProvider theme={theme}>
              <ButtonGroup color='primary' variant="outlined" aria-label="outlined button group" sx={{ border: '2px solid' }}>
                <Button name="allStaking" onClick={handleDialogOpen} variant="outlined" sx={{}}>전체 예치</Button>
                <Button name="staking" onClick={handleDialogOpen} variant="outlined">선택 예치</Button>
                <Button name="unStaking" onClick={handleDialogOpen} variant="outlined">스테이킹 해제</Button>
              </ButtonGroup>
            </ThemeProvider>
          </Box>

          {/* 리팩토링 대상 */}
          <Dialog
            open={dialogOpen.allStaking}
            onClose={handleDialogClose}
            TransitionComponent={Transition}
            keepMounted
            aria-describedby="alert-dialog-slide-description"
          >
            <DialogTitle>{"전체 예치"}</DialogTitle>
            <DialogContent>
              보유하신 MTDZ 전체를 예치를 하시겠습니까?
            </DialogContent>
            <DialogActions>
              <Button variant="outlined" onClick={handleDialogClose}>취소</Button>
              <Button variant="outlined" onClick={allStakingTransfer}>확인</Button>
            </DialogActions>
          </Dialog>

          <Dialog
            open={dialogOpen.staking}
            onClose={handleDialogClose}
            TransitionComponent={Transition}
            keepMounted
            aria-describedby="alert-dialog-slide-description"
          >
            <DialogTitle>{"선택 예치"}</DialogTitle>
            <DialogContent>
              선택된 {choiceItems}를 예치 하시겠습니까?
            </DialogContent>
            <DialogActions>
              <Button variant="outlined" onClick={handleDialogClose}>취소</Button>
              <Button variant="outlined" onClick={stakingTransfer}>확인</Button>
            </DialogActions>
          </Dialog>

          <Dialog
            open={dialogOpen.unStaking}
            onClose={handleDialogClose}
            TransitionComponent={Transition}
            keepMounted
            aria-describedby="alert-dialog-slide-description"
          >
            <DialogTitle>{"스테이킹 해제"}</DialogTitle>
            <DialogContent>
              선택된  {choiceItems}를 <b style={{ color: 'red' }}>해제</b> 하시겠습니까?
            </DialogContent>
            <DialogActions>
              <Button variant="outlined" onClick={handleDialogClose}>취소</Button>
              <Button variant="outlined" onClick={unStakingTransfer}>확인</Button>
            </DialogActions>
          </Dialog>
          {/* 리팩토링 대상 */}

        </Toolbar>
      </AppBar>

      {!check &&
        <Box sx={{ minWidth: 1152, p: 1, height: '714px', overflowY: 'scroll', display: 'flex', flexWrap: 'wrap' }}>
           {[...new Array(10)].map((item, index) => {
            return (
              <Card sx={{ width: 215, height: 330, mt: 1, mb: 1, ml: 1 }} key={index} >
                <Skeleton variant="rectangular" width={215} height={260} />
              </Card>
            )
           })}
        </Box>
      }

      {check &&
        <Box sx={{ minWidth: 1152, p: 1, height: '714px', overflowY: 'scroll', display: 'flex', flexWrap: 'wrap' }} >
          {myItemsList && myItemsList.map((item, index) => <ProfileCard key={index} index={index} item={item} onChange={onChange} />)}
        </Box>
      }

    </Box>
  );
}