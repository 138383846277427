import React from 'react'
import Box from '@mui/material/Box';
import MainContentLeftArea from "../components/MainContentLeftArea";
import MainContentRightArea from "../components/MainContentRightArea";

function MainContentContainer({ check, stakingCountNumber, timeData }) {
  return (
    <Box sx={{ display: 'flex', marginTop: 2, height: '310px' }}>
      { timeData && <MainContentLeftArea stakingCount={stakingCountNumber} timeData={timeData}/> }
      { check && <MainContentRightArea /> }
    </Box>
  )
}

export default MainContentContainer;