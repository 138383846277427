import React from 'react'
import { Box, Typography } from '@mui/material'
import ButtonArea from "../components/ButtonArea";
import footerLogo from "../static/images/footer_logo.png";
import footPcBg from "../static/images/foot_pc_bg.png";

function FooterContainer() {
  const fontSize = `0.7rem`;
  const footerTextLeftBox = {
    width: '144px',
    mr: 1,
    borderRight: '1px solid rgba(255, 255, 255, 10%)',
    fontSize
  };

  const footerTextStyle = {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    mt: 0.8
  };

  const footerTextBoxStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '0.8rem',
    width: 1160,
    height: 180,
  }

  return (
    <Box sx={{mt: 44}}>
      <Box sx={{ height: 16, width: '100%', background: `url(${footPcBg})` }} />
      <Box sx={{ width: '100%', height: 180, background: "#1b1b1b", display: 'flex', justifyContent: 'center', pt: 1 }}>
        <Box sx={footerTextBoxStyle}>
          <Box sx={{ width: 81, mr: '30px' }}>
            <img src={footerLogo} alt="footerLogo" />
          </Box>
          <Box sx={{ width: 484, color: 'rgba(255, 255, 255, 50%)' }}>
            <Box>
              <Typography sx={{ color: 'rgba(255, 255, 255, 82%)', fontSize }}>
                SANDBOX NETWORK
              </Typography>
            </Box>

            <Box sx={footerTextStyle}>
              <Box>
                <Typography sx={footerTextLeftBox}>
                  CEO
                </Typography>
              </Box>
              <Box>
                <Typography sx={{ fontSize }}>
                  Pil-sung Lee
                </Typography>
              </Box>
            </Box>

            <Box sx={footerTextStyle}>
              <Box>
                <Typography sx={footerTextLeftBox}>
                  Business License
                </Typography>
              </Box>
              <Box>
                <Typography sx={{ fontSize }}>
                  220-88-89136
                </Typography>
              </Box>
            </Box>

            <Box sx={footerTextStyle}>
              <Box>
                <Typography sx={footerTextLeftBox}>
                  Address
                </Typography>
              </Box>
              <Box>
                <Typography sx={{ fontSize }}>
                  29~30F, 17, Seobinggo-ro, Yongsan-gu, Seoul, Republic of Korea
                </Typography>
              </Box>
            </Box>

          </Box>
          <ButtonArea />
        </Box>
      </Box>
      <Box sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          fontSize: 0.7,
          color: 'rgba(255, 255, 255, 80%)',
          background: "#1b1b1b",
          pb: 2
        }}>
          Copyright © 2022 Meta toy dragonz. All Rights Reserved
        </Box>
    </Box>
  )
}

export default FooterContainer;